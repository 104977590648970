import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		address: ''
	},
	mutations: {
		updateAddress(state, data) {
			state.address = data
		}
	},
	actions: {
		
	},
	getters: {
		
	},
})
export default store